import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import Pagination from "../Components/Pagination";
import {
  changeAdvertisementStatus,
  fetchAdvertisements,
} from "../Api/Advertisments";
import { useDashboard } from "../Context/DashboardContext";
import { isSuper } from "../Api/api";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";

function Advertisements() {
  const { t } = useTranslation();
  const {
    updateAds,
    setUpdateAds,
    paginationKey,
    setPaginationKey,
    laundries,
    getLaundriesData,
  } = useDashboard();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [loading, setLoading] = useState(true);
  const [advertisements, setAdvertisements] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [activeStatus, setActiveStatus] = useState("");
  const [selectedLaundryId, setSelectedLaundryId] = useState("");
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);

  const search = async (e) => {
    setQuery(e.target.value);
    query && (await getAdvertisementsData());
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getAdvertisementsData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAdvertisements(
        activeStatus,
        selectedLaundryId,
        query,
        currentPage
      );
      console.log(response);
      setAdvertisements(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get admins data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };
  const handleChangeStatus = async (id, status) => {
    try {
      setUpdateAds((prev) => prev + 1);
      const response = await changeAdvertisementStatus(id, status);
      console.log(response);
    } catch (err) {
      console.error("change ad status err : ", err);
    }
  };

  useEffect(() => {
    getAdvertisementsData();
  }, [updateAds, query, selectedLaundryId, activeStatus, currentPage]);
  useEffect(() => {
    getLaundriesData(0);
  }, []);
  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <div className="flex md:items-center max-md:flex-col gap-3">
        {!isSuper && (
          <Link
            to={`addNewAdvertisement`}
            className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[157px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300"
          >
            {t("Add new advertisement")}
          </Link>
        )}
        <select
          className="md:min-w-[200px] md:max-w-[200px] min-h-[38.34px] bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300"
          value={activeStatus}
          onChange={(e) => {
            setActiveStatus(e.target.value);
            setPaginationKey((prev) => prev + 1);
          }}
        >
          <option className="text-MainText" value="">
            {t("All Status")}
          </option>
          <option className="text-MainText" value="0">
            {t("Pending")}
          </option>
          <option className="text-MainText" value="1">
            {t("Confirmed")}
          </option>
          <option className="text-MainText" value="2">
            {t("Cancelled")}
          </option>
        </select>
        {isSuper && (
          <select
            className="md:min-w-[200px] md:max-w-[200px] min-h-[38.34px] w-fit bg-white outline-none focus:ring-0 cursor-pointer max-md:w-full font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300"
            value={selectedLaundryId}
            onChange={(e) => {
              setSelectedLaundryId(e.target.value);
              setPaginationKey((prev) => prev + 1);
            }}
          >
            <option className="text-MainText" value="">
              {t("All Laundrires")}
            </option>
            {laundries.map((item) => (
              <option value={item.id}>
                {lng === "Arabic" ? item.name_ar : item.name_en}
              </option>
            ))}
          </select>
        )}
        <SearchInput
          query={query}
          search={(e) => search(e)}
          placeHolder={t("Search by advretisement name")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem content={t("English Name")} />
              <MainHeadTableItem
                content={t("Arabic Name")}
                className={`max-md:hidden`}
              />
              {isSuper && (
                <MainHeadTableItem
                  content={t("Laundry Name")}
                  className={`max-md:hidden`}
                />
              )}
              <MainHeadTableItem
                content={t("Img")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Points")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Start Date")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("End Date")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Status")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Actions")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 5 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: isSuper ? 9 : 8 }).map((_, inIndex) => (
                    <td key={inIndex}>
                      <Skeleton
                        width={"100%"}
                        className="h-[100px] max-md:h-[50px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : advertisements.length > 0 ? (
              advertisements.map((advertisement) => (
                <>
                  <tr key={advertisement.id} className="">
                    <MainBodyTableItem
                      content={advertisement.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem content={advertisement.name_en} />
                    <MainBodyTableItem
                      content={advertisement.name_ar}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={advertisement.laundry.name_en}
                      className={`max-md:hidden`}
                    />

                    {isSuper && (
                      <MainBodyTableItem
                        content={advertisement.laundry.name_en}
                        className={`max-md:hidden`}
                      />
                    )}
                    <MainBodyTableItem
                      content={advertisement?.media.map((pic) => (
                        <img
                          className={`${
                            advertisement?.media.length > 1 && "mb-1"
                          } w-full h-full max-w-[200px] max-h-[150px] mx-auto object-cover`}
                          src={pic.url_image}
                          alt=""
                        />
                      ))}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={advertisement.points}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={advertisement.start_date}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={advertisement.end_date}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={advertisement.status}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        !isSuper ? (
                          <Link
                            to={`${advertisement.id}/updateAdvertisement`}
                            className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                          >
                            {t("Edit")}
                          </Link>
                        ) : advertisement.status === "pending" ? (
                          <div className="flex flex-col gap-1">
                            <button
                              onClick={() =>
                                handleChangeStatus(advertisement.id, 1)
                              }
                              className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                            >
                              {t("Confirm")}
                            </button>
                            <button
                              onClick={() =>
                                handleChangeStatus(advertisement.id, 2)
                              }
                              className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                            >
                              {t("Delay")}
                            </button>
                            <Link
                              to={`${advertisement.id}/updateAdvertisement`}
                              className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                            >
                              {t("Edit")}
                            </Link>
                          </div>
                        ) : (
                          <div className="flex flex-col gap-1">
                            <button
                              onClick={() =>
                                handleChangeStatus(advertisement.id, 0)
                              }
                              className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                            >
                              {t("Request")}
                            </button>
                            <Link
                              to={`${advertisement.id}/updateAdvertisement`}
                              className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                            >
                              {t("Edit")}
                            </Link>
                          </div>
                        )
                      }
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(advertisement.id)}
                        >
                          {moreOpen[advertisement.id] ? t("Less") : t("More")}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[advertisement.id]}>
                    <tbody>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Id")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {advertisement.id}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Name")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {lng === "Arabic"
                            ? advertisement.name_ar
                            : advertisement.name_en}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Laundry Name")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {advertisement?.laundry?.name_en}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Img")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {advertisement?.media.map((pic) => (
                            <img
                              className={`${
                                advertisement?.media.length > 1 && "mb-1"
                              } w-full h-full max-w-[200px] max-h-[150px] mx-auto object-cover`}
                              src={pic.url_media}
                              alt=""
                            />
                          ))}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Points")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {advertisement.points}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Start Date")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {advertisement.start_date}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("End Date")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {advertisement.end_date}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Status")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {advertisement.status}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Actions")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {!isSuper ? (
                            <Link
                              to={`${advertisement.id}/updateAdvertisement`}
                              className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                            >
                              {t("Edit")}
                            </Link>
                          ) : advertisement.status === "pending" ? (
                            <div className="flex flex-col gap-1">
                              <button
                                onClick={() =>
                                  handleChangeStatus(advertisement.id, 1)
                                }
                                className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                              >
                                {t("Confirm")}
                              </button>
                              <button
                                onClick={() =>
                                  handleChangeStatus(advertisement.id, 2)
                                }
                                className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                              >
                                {t("Delay")}
                              </button>
                              <Link
                                to={`${advertisement.id}/updateAdvertisement`}
                                className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                              >
                                {t("Edit")}
                              </Link>
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                handleChangeStatus(advertisement.id, 0)
                              }
                              className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                            >
                              {t("Request")}
                            </button>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No admins available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          key={paginationKey}
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Advertisements;
