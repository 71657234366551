import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

function MainDashPageItem({ link, onClick, loading, content, number, icon }) {
  return (
    <Link
      onClick={onClick}
      to={link}
      className="bg-white md:min-w-[270px] font-semibold h-fit flex justify-between items-center gap-3 p-[20px] rounded-[0.25rem] text-MainText shadow-lg border border-[#9ea5ad] hover:border-MainText   duration-300"
    >
      <div className="flex flex-col gap-2 font-[500]">
        <div className="text-[13px]">{content}</div>
        <div className="text-[18px]">
          {loading ? <Skeleton width={25} /> : number}
        </div>
      </div>
      <div className="text-[25px] p-2 rounded-full bg-PrimaryColor text-white">
        {icon}
      </div>
    </Link>
  );
}

export default MainDashPageItem;
