import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchLaundryById } from "../Api/Laundry";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";

function Products() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const laundryId = localStorage.getItem("laundryId");
  const { t } = useTranslation();
  const [laundry, setLaundry] = useState({});
  const [loading, setLoading] = useState(false);
  const [moreOpen, setMoreOpen] = useState({});

  const toggleMoreOpen = (productId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[productId] = !prevState[productId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== productId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getLaundryDataById = async (id) => {
    try {
      setLoading(true);
      const response = await fetchLaundryById(id);
      console.log(response);
      console.log(response.data);
      setLaundry(response.data);
    } catch (err) {
      console.error("get laundry data by id err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLaundryDataById(laundryId);
  }, []);

  return (
    <OutterTable>
      <thead>
        <tr>
          <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
          <MainHeadTableItem content={t("English Name")} />
          <MainHeadTableItem
            content={t("Arabic Name")}
            className={`max-md:hidden`}
          />
          <MainHeadTableItem content={t("Image")} className={`max-md:hidden`} />
          <MainHeadTableItem content={t("Price")} className={`max-md:hidden`} />
          <MainHeadTableItem
            content={t("English Service Name")}
            className={`max-md:hidden`}
          />
          <MainHeadTableItem
            content={t("Arabic Service Name")}
            className={`max-md:hidden`}
          />
          <MainHeadTableItem
            content={t("Actions")}
            className={`max-md:hidden`}
          />
          <MainHeadTableItem className={`md:hidden w-[70px]`} />
        </tr>
      </thead>
      <tbody>
        {loading
          ? Array.from({ length: 10 }).map((_, outterIndex) => (
              <tr key={outterIndex}>
                {Array.from({ length: 8 }).map((_, index) => (
                  <td key={index} className={`${index > 2 && "max-md:hidden"}`}>
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ))
          : laundry?.price?.map((item) => (
              <>
                <tr>
                  <MainBodyTableItem
                    content={item?.id}
                    className={`w-[70px]`}
                  />
                  <MainBodyTableItem
                    content={item?.laundry_item?.item_type_en}
                  />
                  <MainBodyTableItem
                    content={item?.laundry_item?.item_type_ar}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={
                      <div className="max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[100px] m-auto overflow-hidden">
                        <img
                          className="w-full h-full object-cover"
                          src={item?.laundry_item?.url_image}
                          alt=""
                        />
                      </div>
                    }
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={`${item?.price} $`}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={item?.service?.name_en}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={item?.service?.name_ar}
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={
                      <Link
                        to={`${item.id}`}
                        className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                      >
                        {t("Edit")}
                      </Link>
                    }
                    className={`max-md:hidden`}
                  />
                  <MainBodyTableItem
                    content={
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(item.id)}
                      >
                        {toggleMoreOpen[item.id] ? t("Less") : t("More")}
                      </span>
                    }
                    className={`md:hidden w-[70px]`}
                  />
                </tr>
                <InnerTable openStatus={moreOpen[item.id]}>
                  <tbody>
                    <tr>
                      <MainBodyTableItem
                        content={t("Id")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={item.id} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("English Name")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={item?.laundry_item?.item_type_en}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Arabic Name")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={item?.laundry_item?.item_type_ar}
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Price")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={item.pivot?.price} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Image")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={
                          <img
                            className="w-full h-full max-w-[200px] max-h-[150px] mx-auto object-cover"
                            src={item?.laundry_item?.url_image}
                            alt=""
                          />
                        }
                      />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("English Service Name")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={item?.service?.name_en} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Arabic Service Name")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem content={item?.service?.name_ar} />
                    </tr>
                    <tr>
                      <MainBodyTableItem
                        content={t("Actions")}
                        className={`font-bold`}
                      />
                      <MainBodyTableItem
                        content={
                          <Link
                            to={`${item.id}`}
                            className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                          >
                            {t("Edit")}
                          </Link>
                        }
                      />
                    </tr>
                  </tbody>
                </InnerTable>
              </>
            ))}
      </tbody>
    </OutterTable>
  );
}

export default Products;
