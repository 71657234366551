import React from "react";

function MainBodyTableItem({ className, content }) {
  return (
    <td
      className={`${className} border border-[#9ea5ad] text-center p-[12px] text-[13px]`}
    >
      {content}
    </td>
  );
}

export default MainBodyTableItem;
