// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getMessaging, getToken} from "firebase/messaging"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXLKd28vfEXPKDbGL7hFLfXZZKckrgDV0",
  authDomain: "dobe-pro.firebaseapp.com",
  projectId: "dobe-pro",
  storageBucket: "dobe-pro.appspot.com",
  messagingSenderId: "787189381875",
  appId: "1:787189381875:web:e649b6abcc8938b4cb9586"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app)

export const generateToken = async () => {
    const permession = await Notification.requestPermission()
    console.log("permission is : ", permession)
    if (permession === "granted") {
        try {

            const token =  await getToken(messaging, {
                 vapidKey: "BJ6Fu5i1aNGC989yMEPpnZqdZLdk0tvQUNWEQJEMW38AZCSYdVKTfWYOltUDbxg1n03WVIt8TDRGXtnP0got33g"
            })
             console.log(token)
        }
        catch (err) {
            console.error("generate token error is : ",err)
        }
    }
}