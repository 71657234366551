import React from "react";

function MainHeadTableItem({ className, content }) {
  return (
    <th className={`${className} border border-[#9ea5ad] p-[12px] text-[13px]`}>
      {content}
    </th>
  );
}

export default MainHeadTableItem;
