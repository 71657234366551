import React, { useEffect, useState } from "react";
import { fetchDriverById, updateDriver } from "../Api/Drivers";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

function UpdateDriver() {
  const { t } = useTranslation();
  const { id } = useParams();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [dataLoading, setDataLoading] = useState(true);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [picture, setPicture] = useState(null);
  const [pictureFromApi, setPictureFromApi] = useState(null);
  const [loading, setLoading] = useState(false);
  const getDriverDataById = async () => {
    try {
      setDataLoading(true);
      const response = await fetchDriverById(id);
      console.log(response);
      setName(response.data.name);
      setPictureFromApi(response.data.photo);
    } catch (err) {
      console.error("get driver by id err : ", err);
    } finally {
      setDataLoading(false);
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("photo", picture);
    formData.append("password", password);
    try {
      setLoading(true);
      const response = await updateDriver(id, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/drivers";
      }
    } catch (err) {
      console.error("update driver err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDriverDataById();
  }, []);
  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <div className="font-[500]">{t("Update Driver")}</div>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="name"
              >
                {t("Name")} <span className="text-red-600">*</span> :
              </label>
              <input
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                id="name"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Name")}
              />
            </div>
          )}
          {dataLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div className="flex flex-col gap-2">
              <label
                className="text-[13px] flex items-center gap-1 cursor-pointer"
                htmlFor="password"
              >
                {t("Password")} <span className="text-red-600">*</span> :
              </label>
              <div className="w-full relative">
                <input
                  type={hidePassword ? "password" : "text"}
                  value={password}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  className={`${
                    lng !== "Arabic" ? "pr-5 pl-[12px]" : "pl-5 pr-[12px]"
                  } py-[7.5px] shadow-lg text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]`}
                  placeholder={t("Password")}
                />
                <div
                  onClick={() => setHidePassword(!hidePassword)}
                  className={`${
                    lng === "Arabic"
                      ? "left-0 rounded-l-[0.25rem]"
                      : "right-0 rounded-r-[0.25rem]"
                  } absolute cursor-pointer top-[50%] -translate-y-[50%] text-[13px] text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 h-full p-2 flex justify-center items-center`}
                >
                  {hidePassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
            </div>
          )}
        </div>
        {dataLoading ? (
          <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
        ) : (
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="img"
            >
              {t("Picture")} <span className="text-red-600">*</span> :
            </label>
            <input
              onChange={(e) => setPicture(e.target.files[0])}
              type="file"
              id="img"
              className="bg-white py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
              placeholder={t("Picture")}
            />
          </div>
        )}
        {dataLoading ? (
          <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[250px]" />
        ) : (
          <div
            className={`${
              (picture === null || pictureFromApi === null) && "hidden"
            } flex flex-wrap gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white`}
          >
            <div className="basis-[33%] max-w-[33%] max-h-[300px]">
              <img
                className="w-full h-full object-cover"
                src={picture ? URL.createObjectURL(picture) : pictureFromApi}
                alt={`driver image`}
              />
            </div>
          </div>
        )}
      </div>
      <button
        type="submit"
        onClick={submitHandler}
        className={`${
          loading ? "cursor-default pointer-events-none" : "cursor-pointer"
        } min-w-[81.38px] min-h-[38.34px] py-[7.5px] px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          t("Update")
        )}
      </button>
    </form>
  );
}

export default UpdateDriver;
