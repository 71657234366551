import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { fetchAndSearchForPersons } from "../Api/Laundry";
import Pagination from "../Components/Pagination";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";

function Users() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);

  const search = async (e) => {
    setQuery(e.target.value);
    query && (await getAdminsData());
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getAdminsData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAndSearchForPersons(query, 2);
      console.log(response);
      setUsers(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get admins data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };

  useEffect(() => {
    getAdminsData();
  }, [query]);
  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <div className="flex md:items-center max-md:flex-col gap-3">
        <SearchInput
          query={query}
          search={(e) => search(e)}
          placeHolder={t("Search by admin name")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem content={t("Name")} />
              <MainHeadTableItem
                content={t("Email")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: 3 }).map((_, inIndex) => (
                    <td key={inIndex}>
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : users.length > 0 ? (
              users.map((user) => (
                <>
                  {" "}
                  <tr key={user.id} className="">
                    <MainBodyTableItem
                      content={user.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem content={user.name} />
                    <MainBodyTableItem
                      content={user.email}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(user.id)}
                        >
                          {moreOpen[user.id] ? t("Less") : t("More")}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[user.id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={user.id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={user.name} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Email")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={user.email} />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No admins available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Users;
