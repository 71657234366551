import React, { useEffect, useState } from "react";
import {
  fetchCarsByLaundry,
  fetchDriversByLaundry,
  fetchLaundryById,
  fetchOrdersByLaundry,
} from "../Api/Laundry";
import { Link, useParams } from "react-router-dom";
import { isSuper, lng } from "../Api/api";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useDashboard } from "../Context/DashboardContext";
import Pagination from "../Components/Pagination";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { usePopup } from "../Context/PopupContext";
import { updateCarStatus } from "../Api/Cars";
import { toastSuccess } from "../Components/ToastNotification/Toast";

function LaundryById() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [laundry, setLaundry] = useState({});
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [carsLoading, setCarsLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [driversLoading, setDriversLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [moreOpenAddresses, setMoreOpenAddresses] = useState({});
  const [moreOpenItems, setMoreOpenItems] = useState({});
  const [moreOpenOrders, setMoreOpenOrders] = useState({});
  const [moreOpenDrivers, setMoreOpenDrivers] = useState({});
  const [moreOpenCars, setMoreOpenCars] = useState({});
  const [currentPage, setCurrentPage] = useState({
    orders: 1,
    drivers: 1,
    cars: 1,
  });
  const [totalPages, setTotalPages] = useState({
    orders: 0,
    drivers: 0,
    cars: 0,
  });
  const [itemPerPage, setItemPerPage] = useState({
    orders: "",
    drivers: "",
    cars: "",
  });
  const [total, setTotal] = useState({ orders: "", drivers: "", cars: "" });
  const [from, setFrom] = useState({ orders: "", drivers: "", cars: "" });
  const [to, setTo] = useState({ orders: "", drivers: "", cars: "" });
  const { updateCars, setUpdateCars } = useDashboard();
  const { setAction, setType, setMakeDecisionOpen, setItemId } = usePopup();
  const toggleMoreOpen = (type, itemId) => {
    let moreFunction;
    switch (type) {
      case "item":
        moreFunction = setMoreOpenItems;
        break;
      case "order":
        moreFunction = setMoreOpenOrders;
        break;
      case "driver":
        moreFunction = setMoreOpenDrivers;
        break;
      case "car":
        moreFunction = setMoreOpenCars;
        break;
      case "address":
        moreFunction = setMoreOpenAddresses;
        break;
    }
    moreFunction((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[itemId] = !prevState[itemId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== itemId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getLaundryDataById = async (id) => {
    try {
      setLoading(true);
      const response = await fetchLaundryById(id);
      console.log(response);
      setLaundry(response.data);
    } catch (err) {
      console.error("get laundry data by id err : ", err);
    } finally {
      setLoading(false);
    }
  };

  const getCarsData = async (id, page) => {
    try {
      setCarsLoading(true);
      const response = await fetchCarsByLaundry(id, page);
      console.log(response);
      setCars(response.data.data);
      setCurrentPage((prev) => ({
        ...prev,
        cars: response.data.current_page,
      }));
      setTotalPages((prev) => ({
        ...prev,
        cars: response.data.last_page,
      }));
      setItemPerPage((prev) => ({
        ...prev,
        cars: response.data.per_page,
      }));
      setTotal((prev) => ({
        ...prev,
        cars: response.data.total,
      }));
      setFrom((prev) => ({ ...prev, cars: response.data.from }));
      setTo((prev) => ({
        ...prev,
        cars: response.data.to,
      }));
    } catch (err) {
      console.error("get cars by id data err : ", err);
    } finally {
      setCarsLoading(false);
    }
  };
  const handleUpdateStatus = async (id) => {
    try {
      const response = await updateCarStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateCars((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update car status err : ", err);
    }
  };

  const getDriversData = async (id, page) => {
    try {
      setDriversLoading(true);
      const response = await fetchDriversByLaundry(id, page);
      console.log(response);
      setDrivers(response.data.data);
      setCurrentPage((prev) => ({
        ...prev,
        drivers: response.data.current_page,
      }));
      setTotalPages((prev) => ({
        ...prev,
        drivers: response.data.last_page,
      }));
      setItemPerPage((prev) => ({
        ...prev,
        drivers: response.data.per_page,
      }));
      setTotal((prev) => ({
        ...prev,
        drivers: response.data.total,
      }));
      setFrom((prev) => ({ ...prev, drivers: response.data.from }));
      setTo((prev) => ({
        ...prev,
        drivers: response.data.to,
      }));
    } catch (err) {
      console.error("get drivers by id data err : ", err);
    } finally {
      setDriversLoading(false);
    }
  };

  const getOrdersData = async (id, page) => {
    try {
      setOrdersLoading(true);
      const response = await fetchOrdersByLaundry(id, page);
      console.log(response);
      setOrders(response.data.data);
      setCurrentPage((prev) => ({
        ...prev,
        orders: response.data.current_page,
      }));
      setTotalPages((prev) => ({
        ...prev,
        orders: response.data.last_page,
      }));
      setItemPerPage((prev) => ({
        ...prev,
        orders: response.data.per_page,
      }));
      setTotal((prev) => ({
        ...prev,
        orders: response.data.total,
      }));
      setFrom((prev) => ({ ...prev, orders: response.data.from }));
      setTo((prev) => ({
        ...prev,
        orders: response.data.to,
      }));
    } catch (err) {
      console.error("get orders by id data err : ", err);
    } finally {
      setOrdersLoading(false);
    }
  };

  const handlePageClickOrders = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage.orders);
  };

  const handlePageClickDrivers = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage.drivers);
  };

  const handlePageClickCars = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage.cars);
  };

  useEffect(() => {
    getLaundryDataById(id);
  }, [lng]);
  useEffect(() => {
    getDriversData(id, currentPage.drivers || 1);
  }, [currentPage.drivers]);
  useEffect(() => {
    getCarsData(id, currentPage.cars || 1);
  }, [updateCars, currentPage.cars]);
  useEffect(() => {
    getOrdersData(id, currentPage.orders || 1);
  }, [currentPage.orders]);
  return (
    <div className="flex flex-col gap-3">
      <Link
        to={`updateLaundry`}
        className="bg-white outline-none focus:ring-0 w-fit min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
      >
        {t("Edit this laundry")}
      </Link>
      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] max-md:hidden">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("English Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Arabic Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("English Description")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Arabic Description")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("Phone Number")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                {Array.from({ length: 5 }).map((_, index) => (
                  <td key={index}>
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ) : (
              <tr>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.name_en}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.name_ar}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.description_en}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.description_ar}
                </td>
                <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                  {laundry.phone_number}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem] md:hidden">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <tbody>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("English Name")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.name_en
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Arabic Name")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.name_ar
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("English Description")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.description_en
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Arabic Description")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.description_ar
                )}
              </td>
            </tr>
            <tr>
              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {t("Phone Number")}
              </td>
              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    className="h-[55px] max-md:h-[25px]"
                  />
                ) : (
                  laundry.phone_number
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="font-[500]">{t("Laundry Addresses")} : </div>
      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem]">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("City")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Address Line")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Lat,Lng")}
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                {Array.from({ length: 3 }).map((_, index) => (
                  <td key={index} className={`${index < 1 && "max-md:hidden"}`}>
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ) : (
              <>
                <tr>
                  <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                    {laundry?.addresses?.city}
                  </td>
                  <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center max-md:hidden">
                    {laundry?.addresses?.address_line_1}
                  </td>
                  <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center max-md:hidden">
                    {`(${laundry?.addresses?.lat},${laundry?.addresses?.lng})`}
                  </td>
                </tr>
                <div
                  className={`${
                    moreOpenAddresses[laundry?.addresses?.id]
                      ? "block"
                      : "hidden"
                  } innerTable md:hidden`}
                >
                  <table className="w-full">
                    <tbody>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("City")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {laundry?.addresses?.city}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Address Line")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {laundry?.addresses?.address_line_1}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Lat,Lng")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {`(${laundry?.addresses?.lat},${laundry?.addresses?.lng})`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="font-[500]">{t("Laundry Items With Services")} : </div>
      <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem]">
        <table
          className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
        >
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                {t("English Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Arabic Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Image")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Price")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("English Service Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Arabic Service Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] md:hidden w-[70px]">
                ...
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                {Array.from({ length: 6 }).map((_, index) => (
                  <td key={index} className={`${index > 1 && "max-md:hidden"}`}>
                    <Skeleton
                      width={"100%"}
                      className="h-[55px] max-md:h-[25px]"
                    />
                  </td>
                ))}
              </tr>
            ) : (
              laundry.price.map((item) => (
                <>
                  <tr>
                    <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center">
                      {item?.laundry_item?.item_type_en}
                    </td>
                    <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center max-md:hidden">
                      {item?.laundry_item?.item_type_ar}
                    </td>
                    <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center max-md:hidden">
                      <div className="max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[100px] mx-auto">
                        <img
                          className="w-full h-full object-cover"
                          src={item?.laundry_item?.url_image}
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center max-md:hidden">
                      {item?.price}
                    </td>
                    <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center max-md:hidden">
                      {item?.service?.name_en}
                    </td>
                    <td className="border border-[#9ea5ad] p-[12px] text-[13px] text-center max-md:hidden">
                      {item?.service?.name_ar}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen("item", item.id)}
                      >
                        {moreOpenItems[item.id] ? t("Less") : t("More")}
                      </span>
                    </td>
                  </tr>
                  <div
                    className={`${
                      moreOpenItems[item.id] ? "block" : "hidden"
                    } innerTable md:hidden`}
                  >
                    <table
                      className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
                    >
                      <tbody>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("English Name")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {loading ? (
                              <Skeleton
                                width={"100%"}
                                className="h-[55px] max-md:h-[25px]"
                              />
                            ) : (
                              item.item_type_en
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Arabic Name")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {loading ? (
                              <Skeleton
                                width={"100%"}
                                className="h-[55px] max-md:h-[25px]"
                              />
                            ) : (
                              item.item_type_ar
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Price")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {loading ? (
                              <Skeleton
                                width={"100%"}
                                className="h-[55px] max-md:h-[25px]"
                              />
                            ) : (
                              item.pivot?.price
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Image")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {loading ? (
                              <Skeleton
                                width={"100%"}
                                className="h-[55px] max-md:h-[25px]"
                              />
                            ) : (
                              <img
                                className="w-full h-full max-w-[200px] max-h-[150px] mx-auto object-cover"
                                src={item?.laundry_item?.url_image}
                                alt=""
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("English Service Name")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {item?.service?.name_en}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {t("Arabic Service Name")}
                          </td>
                          <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                            {item?.service?.name_ar}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="font-[500]">{t("Laundry Images")} : </div>
      {loading ? (
        <Skeleton className="min-w-full min-h-[300px] max-md:min-h-[200px]" />
      ) : (
        <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white">
          {laundry.LaundryMedia.map((pic, index) => (
            <div
              key={pic.id}
              className="w-full max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[200px]"
            >
              <img
                className="w-full h-full object-cover"
                src={pic.url_image}
                alt={`image ${index}`}
              />
            </div>
          ))}
        </div>
      )}
      <div className="font-[500]">{t("Laundry Orders")} : </div>
      <div className="flex flex-col gap-3">
        <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem]">
          <table
            className={`bg-white text-MainText w-full table-fixed max-w-[screen]`}
          >
            <thead>
              <tr>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                  {t("Id")}
                </th>
                {isSuper && (
                  <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                    {t("Laundry Name")}
                  </th>
                )}
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Order Number")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Order Source")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px]">
                  {t("Order Owner")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Status")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Base Cost")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Total Price")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] md:hidden w-[70px]"></th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Note")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Order Date")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Pickup Time")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Delivery Time")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden w-[50px]">
                  ...
                </th>
              </tr>
            </thead>
            <tbody>
              {ordersLoading ? (
                Array.from({ length: 10 }).map((_, outIndex) => (
                  <tr key={outIndex}>
                    {Array.from({ length: 13 }).map((_, inIndex) => (
                      <td
                        key={inIndex}
                        className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                      >
                        <Skeleton
                          width={"100%"}
                          className="h-[55px] max-md:h-[25px]"
                        />
                      </td>
                    ))}
                  </tr>
                ))
              ) : orders.length > 0 ? (
                orders.map((order) => (
                  <>
                    <tr key={order.id} className="">
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                        {order.id}
                      </td>
                      {isSuper && (
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                          {lng === "Arabic"
                            ? order.laundry.name_ar
                            : order.laundry.name_en}
                        </td>
                      )}
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {order.order_number}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {order.type_order}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                        {order.addresses?.full_name}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {order.status}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {parseFloat(order.base_cost).toFixed(2)} $
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {parseFloat(order.total_price).toFixed(2)} $
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen("order", order.id)}
                        >
                          {moreOpenOrders[order.id] ? t("Less") : t("More")}
                        </span>
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {order.note}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {order.order_date}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {order.pickup_time}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {order.delivery_time}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden w-[50px]">
                        {order.order_type.type === "directly" && (
                          <Link to={`/dashboard/orders/${order.id}`}>
                            <FaRegEdit
                              size={15}
                              className={`${
                                order.order_type.type === "directly"
                                  ? "block"
                                  : "hidden"
                              } mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300`}
                            />
                          </Link>
                        )}
                      </td>
                    </tr>

                    <div
                      className={`${
                        moreOpenOrders[order.id] ? "block" : "hidden"
                      } innerTable md:hidden`}
                    >
                      <table className="w-full">
                        <tbody>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Id")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {order.id}
                            </td>
                          </tr>
                          {isSuper && (
                            <tr>
                              <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                                {t("Laundry name")}
                              </td>
                              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                                {lng === "Arabic"
                                  ? order.laundry.name_ar
                                  : order.laundry.name_en}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Order Owner")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {order.addresses?.full_name}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Status")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {order.status}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Base Cost")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {parseFloat(order.base_cost).toFixed(2)} $
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Total Price")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {parseFloat(order.total_price).toFixed(2)} $
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Note")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {order.note}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Order Date")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {order.order_date}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Pickup Time")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {order.pickup_time}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Delivery Time")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {order.delivery_time}
                            </td>
                          </tr>
                          {order.order_type.type === "directly" && (
                            <tr>
                              <td className="font-bold text-center border border-[#9ea5ad] p-[12px] text-[13px]">
                                ...
                              </td>
                              <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                                <Link to={`/dashboard/orders/${order.id}`}>
                                  <FaRegEdit className="mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300" />
                                </Link>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                ))
              ) : (
                <div className="whitespace-nowrap p-[12px] text-[13px]">
                  {t("No orders available")}
                </div>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          totalPages={totalPages.orders}
          currentPage={currentPage.orders}
          itemPerPage={itemPerPage.orders}
          handlePageClick={handlePageClickOrders}
          from={from.orders}
          to={to.orders}
          total={total.orders}
        />
      </div>
      <div className="font-[500]">{t("Laundry Drivers")} : </div>
      <div className="flex flex-col gap-3">
        <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem]">
          <table
            className={`bg-white text-MainText w-full table-fixed max-w-[screen] overflow-x-auto`}
          >
            <thead>
              <tr>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                  {t("Id")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] ">
                  {t("Name")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Email")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Driver id")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden w-[50px]">
                  ...
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px] md:hidden"></th>
              </tr>
            </thead>
            <tbody>
              {driversLoading ? (
                Array.from({ length: 10 }).map((_, outIndex) => (
                  <tr key={outIndex}>
                    {Array.from({ length: 5 }).map((_, inIndex) => (
                      <td
                        key={inIndex}
                        className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                      >
                        <Skeleton
                          width={"100%"}
                          className="h-[55px] max-md:h-[25px]"
                        />
                      </td>
                    ))}
                  </tr>
                ))
              ) : drivers.length > 0 ? (
                drivers.map((driver) => (
                  <>
                    <tr key={driver.id} className="">
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                        {driver?.user?.id}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                        {driver?.user?.name}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {driver?.user?.email}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {driver?.user?.driver_id}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() =>
                            toggleMoreOpen("driver", driver?.user?.id)
                          }
                        >
                          {moreOpenDrivers[driver?.user?.id]
                            ? t("Less")
                            : t("More")}
                        </span>
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden w-[50px]">
                        <span className="flex flex-col items-center">
                          <Link to={`/dashboard/drivers/${driver?.user?.id}`}>
                            <FaRegEdit
                              size={15}
                              className={`mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor mb-2 duration-300`}
                            />
                          </Link>
                          <AiOutlineClose
                            onClick={() => {
                              setAction("delete");
                              setType("driver");
                              setMakeDecisionOpen(true);
                              setItemId(driver?.user?.id);
                            }}
                            className=" cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300"
                          />
                        </span>
                      </td>
                    </tr>
                    <div
                      className={`${
                        moreOpenDrivers[driver?.user?.id] ? "block" : "hidden"
                      } innerTable md:hidden`}
                    >
                      <table className="w-full">
                        <tbody>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Id")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {driver?.user?.id}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Name")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {driver?.user?.name}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Email")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {driver?.user?.email}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Driver id")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {driver?.user?.driver_id}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold text-center border border-[#9ea5ad] p-[12px] text-[13px]">
                              ...
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              <div className="mx-auto flex items-center gap-2 w-fit h-fit">
                                <Link
                                  to={`/dashboard/drivers/${driver?.user?.id}`}
                                >
                                  <FaRegEdit
                                    size={15}
                                    className=" cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                                  />
                                </Link>
                                <AiOutlineClose
                                  onClick={() => {
                                    setAction("delete");
                                    setType("driver");
                                    setMakeDecisionOpen(true);
                                    setItemId(driver?.user?.id);
                                  }}
                                  className=" cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300"
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ))
              ) : (
                <div className="whitespace-nowrap p-[12px] text-[13px]">
                  {t("No drivers available")}
                </div>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          totalPages={totalPages.drivers}
          currentPage={currentPage.drivers}
          itemPerPage={itemPerPage.drivers}
          handlePageClick={handlePageClickDrivers}
          from={from.drivers}
          to={to.drivers}
          total={total.drivers}
        />
      </div>
      <div className="font-[500]">{t("Laundry Cars")} : </div>
      <div className="flex flex-col gap-3">
        <div className="border border-[#9ea5ad] shadow-lg overflow-hidden rounded-[0.25rem]">
          <table
            className={`bg-white text-MainText w-full table-fixed max-w-[screen] overflow-x-auto`}
          >
            <thead>
              <tr>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                  {t("Id")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] ">
                  {t("Car Number")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Status")}
                </th>

                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Driver Name")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Lat")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                  {t("Lng")}
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden w-[50px]">
                  ...
                </th>
                <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px] md:hidden"></th>
              </tr>
            </thead>
            <tbody>
              {carsLoading ? (
                Array.from({ length: 10 }).map((_, outIndex) => (
                  <tr key={outIndex}>
                    {Array.from({ length: 7 }).map((_, inIndex) => (
                      <td
                        key={inIndex}
                        className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                      >
                        <Skeleton
                          width={"100%"}
                          className="h-[55px] max-md:h-[25px]"
                        />
                      </td>
                    ))}
                  </tr>
                ))
              ) : cars.length > 0 ? (
                cars.map((car) => (
                  <>
                    <tr key={car.id} className="">
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                        {car.id}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                        {car.number_car}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        <select
                          onChange={() => handleUpdateStatus(car.id)}
                          value={car.status === 1 ? "Active" : "Not active"}
                          className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                        >
                          <option
                            className="bg-white text-MainText"
                            value="Active"
                          >
                            {t("Active")}
                          </option>
                          <option
                            className="bg-white text-MainText"
                            value="Not active"
                          >
                            {t("Not active")}
                          </option>
                        </select>
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {car.driver?.name}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {car.lat}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                        {car.lng}
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen("car", car.id)}
                        >
                          {moreOpenCars[car.id] ? t("Less") : t("More")}
                        </span>
                      </td>
                      <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden w-[50px]">
                        <Link to={`/dashboard/cars/${car.id}`}>
                          <FaRegEdit
                            size={15}
                            className={`mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor mb-2 duration-300`}
                          />
                        </Link>
                      </td>
                    </tr>
                    <div
                      className={`${
                        moreOpenCars[car.id] ? "block" : "hidden"
                      } innerTable md:hidden`}
                    >
                      <table className="w-full">
                        <tbody>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Id")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {car.id}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Car Number")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {car.number_car}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Status")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              <select
                                onChange={() => handleUpdateStatus(car.id)}
                                value={
                                  car.status === 1 ? "Active" : "Not active"
                                }
                                className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                              >
                                <option
                                  className="bg-white text-MainText"
                                  value="Active"
                                >
                                  {t("Active")}
                                </option>
                                <option
                                  className="bg-white text-MainText"
                                  value="Not active"
                                >
                                  {t("Not active")}
                                </option>
                              </select>
                            </td>
                          </tr>

                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Driver Name")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {car.driver?.name}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Lat")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {car.lat}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {t("Lng")}
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              {car.lng}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-bold text-center border border-[#9ea5ad] p-[12px] text-[13px]">
                              ...
                            </td>
                            <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                              <div className="mx-auto flex items-center gap-2 w-fit h-fit">
                                <Link to={`/dashboard/cars/${car.id}`}>
                                  <FaRegEdit
                                    size={15}
                                    className=" cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                                  />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ))
              ) : (
                <div className="whitespace-nowrap p-[12px] text-[13px]">
                  {t("No cars available")}
                </div>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          totalPages={totalPages.cars}
          currentPage={currentPage.cars}
          itemPerPage={itemPerPage.cars}
          handlePageClick={handlePageClickCars}
          from={from.cars}
          to={to.cars}
          total={total.cars}
        />
      </div>
    </div>
  );
}

export default LaundryById;
