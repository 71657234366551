import { toastError } from "../Components/ToastNotification/Toast";
import { apiAdmin } from "./api";


export const fetchDrivers = async (page,name,laundry_id) => {
  try {
    const urlParams = new URLSearchParams({ page });
    if (name) urlParams.set('name', name);
    if (laundry_id) urlParams.set('laundry_id', laundry_id);
    const response = await apiAdmin.get(`/api/getDriversByLaundryId`, { params: urlParams });
    return response.data;
  } catch (error) {
    console.log("fetch drivers data err : ", error);
  }
};
export const addDriver = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/createDriver`, data);
    return response.data;
  } catch (error) {
    console.error("add driver err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0])
  }
};

export const fetchDriverById = async (driver_id) => {
  try {
    const response = await apiAdmin.get(`/api/DriverById?id=${driver_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch driver by id err : ", error);
  }
};


export const updateDriver = async (driver_id,data) => {
  try {
      const response = await apiAdmin.post(`/api/updateDriver?id=${driver_id}`, data,
          {
              headers: {
        "Content-Type": "multipart/form-data",
              }
          });
    return response.data;
  } catch (error) {
    console.error("update driver err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0])
  }
};

export const deleteDriver = async (driver_id, data) => {
  try {
    const response = await apiAdmin.delete(`/api/deleteDriver?id=${driver_id}`, data);
    return response.data;
  } catch (error) {
    console.error("delete driver err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0])
  }
};