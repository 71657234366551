import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaRegEdit, FaSearch } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Pagination from "../Components/Pagination";
import { fetchAllCars, searchForCar, updateCarStatus } from "../Api/Cars";
import { useDashboard } from "../Context/DashboardContext";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import { isSuper } from "../Api/api";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";

function Cars() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [loading, setLoading] = useState(true);
  const [cars, setCars] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);
  const { updateCars, setUpdateCars } = useDashboard();

  const search = async (e) => {
    setQuery(e.target.value);
    query && getCarsData();
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const handleUpdateStatus = async (id) => {
    try {
      const response = await updateCarStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateCars((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update car status err : ", err);
    }
  };

  const getCarsData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchAllCars(currentPage, query);
      console.log(response);
      setCars(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get cars data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };

  useEffect(() => {
    getCarsData();
  }, [updateCars, query, currentPage]);

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <div className="flex md:items-center max-md:flex-col gap-3">
        <Link
          to={`addNewCar`}
          className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[97px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
        >
          {t("Add new car")}
        </Link>
        <SearchInput
          query={query}
          search={search}
          placeHolder={t("Search by car number")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              <MainHeadTableItem content={t("Car number")} />
              <MainHeadTableItem
                content={t("Status")}
                className={`max-md:hidden`}
              />

              {isSuper && (
                <MainHeadTableItem
                  content={t("Laundry name")}
                  className={`max-md:hidden`}
                />
              )}
              <MainHeadTableItem
                content={t("Driver name")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Lat")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Lng")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem content={"..."} className={`max-md:hidden`} />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: isSuper ? 8 : 7 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : cars.length > 0 ? (
              cars.map((car) => (
                <>
                  <tr key={car.id} className="">
                    <MainBodyTableItem
                      content={car.id}
                      className={`w-[70px]`}
                    />
                    <MainBodyTableItem content={car.number_car} />
                    <MainBodyTableItem
                      content={
                        <select
                          onChange={() => handleUpdateStatus(car.id)}
                          value={car.status === 1 ? "Active" : "Not active"}
                          className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                        >
                          <option
                            className="bg-white text-MainText"
                            value="Active"
                          >
                            {t("Active")}
                          </option>
                          <option
                            className="bg-white text-MainText"
                            value="Not active"
                          >
                            {t("Not active")}
                          </option>
                        </select>
                      }
                      className={`max-md:hidden`}
                    />

                    {isSuper && (
                      <MainBodyTableItem
                        content={
                          lng === "Arabic"
                            ? car?.laundry?.name_ar
                            : car?.laundry?.name_en
                        }
                        className={`max-md:hidden`}
                      />
                    )}
                    <MainBodyTableItem
                      content={car?.driver?.name}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={car.lat}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={car.lng}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(car.id)}
                        >
                          {moreOpen[car.id] ? t("Less") : t("More")}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                    <MainBodyTableItem
                      content={
                        <div className="flex flex-col gap-2">
                          <Link
                            to={`${car.id}/updateCar`}
                            className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                          >
                            {t("Edit")}
                          </Link>
                          <Link
                            to={`${car.id}`}
                            className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                          >
                            {t("More")}
                          </Link>
                        </div>
                      }
                      className={`max-md:hidden`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[car.id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Car number")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.number_car} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Status")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <select
                              onChange={() => handleUpdateStatus(car.id)}
                              value={car.status === 1 ? "Active" : "Not active"}
                              className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                            >
                              <option
                                className="bg-white text-MainText"
                                value="Active"
                              >
                                {t("Active")}
                              </option>
                              <option
                                className="bg-white text-MainText"
                                value="Not active"
                              >
                                {t("Not active")}
                              </option>
                            </select>
                          }
                        />
                      </tr>

                      {isSuper && (
                        <tr>
                          <MainBodyTableItem
                            content={t("Laundry name")}
                            className={`font-bold`}
                          />
                          <MainBodyTableItem
                            content={
                              lng === "Arabic"
                                ? car?.laundry?.name_ar
                                : car?.laundry?.name_en
                            }
                          />
                        </tr>
                      )}
                      <tr>
                        <MainBodyTableItem
                          content={t("Driver name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car?.driver?.name} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Lat")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.lat} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Lng")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={car.lng} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={"..."}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <div className="flex flex-col gap-2">
                              <Link
                                to={`${car.id}/updateCar`}
                                className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                              >
                                {t("Edit")}
                              </Link>
                              <Link
                                to={`${car.id}`}
                                className={`py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText duration-300 flex justify-center items-center min-w-[100px] min-h-[38.34px] mx-auto`}
                              >
                                {t("More")}
                              </Link>
                            </div>
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No cars available")}
              </div>
            )}
          </tbody>
        </OutterTable>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Cars;
