import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchAndSearchForPersons } from "../Api/Laundry";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { FaRegEdit, FaSearch } from "react-icons/fa";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { usePopup } from "../Context/PopupContext";
import { useDashboard } from "../Context/DashboardContext";
import Pagination from "../Components/Pagination";
import { fetchDrivers } from "../Api/Drivers";
import { isSuper } from "../Api/api";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";
import SearchInput from "../Components/SearchInput";
import MainHeadTableItem from "../Components/MainHeadTableItem";
import MainBodyTableItem from "../Components/MainBodyTableItem";

function Drivers() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("dpLanguage") || "English";
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [moreOpen, setMoreOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [total, setTotal] = useState();
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [query, setQuery] = useState("");
  const [searchloading, setSearchLoading] = useState(false);
  const { setType, setAction, setMakeDecisionOpen, setItemId } = usePopup();
  const { updateDrivers } = useDashboard();

  const search = async (e) => {
    setQuery(e.target.value);
    query && getDriversData();
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };
  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const getDriversData = async () => {
    try {
      setLoading(true);
      query && setSearchLoading(true);
      const response = await fetchDrivers(currentPage, query);
      console.log(response);
      setDrivers(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setTotal(response.data.total);
      setFrom(response.data.from);
      setTo(response.data.to);
    } catch (err) {
      console.error("get drivers data err : ", err);
    } finally {
      setLoading(false);
      query && setSearchLoading(false);
    }
  };

  useEffect(() => {
    getDriversData();
  }, [updateDrivers, query]);

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <div className="flex md:items-center max-md:flex-col gap-3">
        <Link
          to={`addNewDriver`}
          className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[113px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
        >
          {t("Add new driver")}
        </Link>
        <SearchInput
          query={query}
          search={search}
          placeHolder={t("Search by driver name")}
          loading={searchloading}
        />
      </div>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <MainHeadTableItem content={t("Id")} className={`w-[70px]`} />
              {isSuper && <MainHeadTableItem content={t("Laundry id")} />}
              <MainHeadTableItem content={t("Name")} />
              <MainHeadTableItem
                content={t("Email")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("Driver id")}
                className={`max-md:hidden`}
              />
              <MainHeadTableItem
                content={t("...")}
                className={`max-md:hidden w-[50px]`}
              />
              <MainHeadTableItem className={`w-[70px] md:hidden`} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 10 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: isSuper ? 6 : 5 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : drivers.length > 0 ? (
              drivers.map((driver) => (
                <>
                  <tr key={driver.user_id} className="">
                    <MainBodyTableItem
                      content={driver.user_id}
                      className={`w-[70px]`}
                    />
                    {isSuper && (
                      <MainBodyTableItem content={driver.laundry_id} />
                    )}
                    <MainBodyTableItem content={driver?.user?.name} />
                    <MainBodyTableItem
                      content={driver?.user?.email}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={driver?.user?.driver_id}
                      className={`max-md:hidden`}
                    />
                    <MainBodyTableItem
                      content={
                        <span
                          className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                          onClick={() => toggleMoreOpen(driver.user_id)}
                        >
                          {moreOpen[driver.user_id] ? t("Less") : t("More")}
                        </span>
                      }
                      className={`md:hidden w-[70px]`}
                    />
                    <MainBodyTableItem
                      content={
                        <span className="flex flex-col items-center">
                          <Link to={`${driver.user_id}`}>
                            <FaRegEdit
                              size={15}
                              className={`mx-auto cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor mb-2 duration-300`}
                            />
                          </Link>
                          <AiOutlineClose
                            onClick={() => {
                              setAction("delete");
                              setType("driver");
                              setMakeDecisionOpen(true);
                              setItemId(driver.user_id);
                            }}
                            size={20}
                            className=" cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300"
                          />
                        </span>
                      }
                      className={`max-md:hidden w-[50px]`}
                    />
                  </tr>
                  <InnerTable openStatus={moreOpen[driver.user_id]}>
                    <tbody>
                      <tr>
                        <MainBodyTableItem
                          content={t("Id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver.id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Name")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver?.user?.name} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Email")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver?.user?.email} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={t("Driver id")}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem content={driver?.user?.driver_id} />
                      </tr>
                      <tr>
                        <MainBodyTableItem
                          content={"..."}
                          className={`font-bold`}
                        />
                        <MainBodyTableItem
                          content={
                            <div className="mx-auto flex items-center gap-2 w-fit h-fit">
                              <Link to={`${driver.user_id}`}>
                                <FaRegEdit
                                  size={15}
                                  className=" cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                                />
                              </Link>
                              <AiOutlineClose
                                onClick={() => {
                                  setAction("delete");
                                  setType("driver");
                                  setMakeDecisionOpen(true);
                                  setItemId(driver.user_id);
                                }}
                                size={20}
                                className=" cursor-pointer text-[#ff2c2c] hover:text-[#df1e1e] duration-300"
                              />
                            </div>
                          }
                        />
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No drivers available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Drivers;
