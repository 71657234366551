import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
      // SelectLanguage
      "Choose Language": "Choose Language",
      English: "English",
      Arabic: "Arabic",
      // Admin
      "Welcome": "Welcome",
      "Please login": "Please login",
      "Email": "Email",
      "Password":"Password",
      "Your Email": "Your Email",
      "Your Password": "Your Password",
      "Login": "Login",
      // MainDashPage 
      "Orders for today": "Orders for today",
      "Total orders": "Total orders",
      "Pending orders": "Pending orders",
      "Cars in service": "Cars in service",
      "Income this month": "Income this month",
      "Orders Chart": "Orders Chart",
      "Daily": "Daily",
      "Monthly": "Monthly",
      "Yearly": "Yearly",
      "Start Date": "Start Date",
      "End Date": "End Date",
      "Update": "Update",
      // Dashboard
      "Super Admin": "Super Admin",
      // Laundries 
      "Add new laundry": "Add new laundry",
      "Id": "Id",
      "Name": "Name",
      "Description": "Description",
      "Phone Number": "Phone Number",
      "Address": "Address",
      "Admin id": "Admin id",
      "Status": "Status",
      "Image": "Image",
      "Active": "Active",
      "Not active": "Not active",
      "More": "More",
      "No laundries available": "No laundries available",
      // LaundryById 
      "Edit this laundry": "Edit this laundry",
      "Lat,Lng": "Lat,Lng",
      "Point": "Point",
      "Laundry Items": "Laundry Items",
      "Price": "Price",
      "Laundry Services": "Laundry Services",
      "Service Name": "Service Name",
      "Laundry Images": "Laundry Images",
      "Laundry Orders": "Laundry Orders",
      "Laundry Name": "Laundry Name",
      "Order Number": "Order Number",
      "Order Source": "Order Source",
      "Order Owner": "Order Owner",
      "Status": "Status",
      "Base Cost": "Base Cost",
      "Total Price": "Total Price",
      "Note": "Note",
      "Order Date": "Order Date",
      "Pickup Time": "Pickup Time",
      "Delivery Time": "Delivery Time",
      "No orders available": "No orders available",
      "Laundry Drivers": "Laundry Drivers",
      "Driver id": "Driver id",
      "No drivers available": "No drivers available",
      "Laundry Cars": "Laundry Cars",
      "Driver Name": "Driver Name",
      "Lat":"Lat",
      "Lng": "Lng",
      "No cars available": "No cars available",
      // AddLaundry
      "Name In English": "Name In English",
      "Name In Arabic": "Name In Arabic",
      "Description In English": "Description In English",
      "Description In Arabic": "Description In Arabic",
      "Phone Number": "Phone Number",
      "City": "City",
      "Point": "Point",
      "Select Admin": "Select Admin",
      "Select Items": "Select Items",
      "Select Services": "Select Services",
      "Lat": "Lat",
      "Lng": "Lng",
      "Images": "Images",
      "Add": "Add",
      // UpdateLaundry 
      "Update laundry": "Update laundry",
      "Update":"Update",
      // EmployeesAndCars
      "Car Id": "Car Id",
      "Driver Name": "Driver Name",
      "Details": "Details",
      "More": "More",
      "No data available": "No data available",
      // DriverById
      "Driver id": "Driver id",
      "Driver name": "Driver name",
      "Driver email": "Driver email",
      "Driver phone": "Driver phone",
      "Car location":"Car location",
      // Orders
      "Orders All Time": "Orders All Time",
      "Orders For Today": "Orders For Today",
      "All Orders": "All Orders",
      "Indirect": "Indirect",
      "Direct": "Direct",
      "Id": "Id",
      "Status": "Status",
      "Base Cost": "Base Cost",
      "Total Price": "Total Price",
      "Note": "Note",
      "Order Date": "Order Date",
      "Pickup Time": "Pickup Time",
      "Delivery Time": "Delivery Time",
      "Less": "Less",
      "More": "More",
      "No orders available": "No orders available",
      // OrdersStatsChart
      "Orders":"Orders",
      // UpdateDeliveryTime
      "Edit Order": "Edit Order",
      "Choose Date":"Choose Date",
      "Choose Time": "Choose Time",
      "Select Car": "Select Car",
      "Select Status": "Select Status",
      "Pending": "Pending",
      "Confirmed": "Confirmed",
      "Request":"Request",
      "Cancelled": "Cancelled",
      "Update": "Update",
                  // ProductItem
      "Edit":"Edit",
      // UpdateProductPrice
      "Update product price": "Update product price",
      "Product Price": "Product Price",
      "Update":"Update",
      // DashboardSidebar
"Dobe Pro": "Dobe Pro",
      "Dashboard": "Dashboard",
      "Laundries": "Laundries",
      "Admins": "Admins",
      "Drivers": "Drivers",
  "Cars":"Cars",
  "Employees And Cars": "Employees And Cars",
  "Orders": "Orders",
  "Transactions": "Transactions",
  "Prices": "Prices",
  "Reports": "Reports",
  "Language": "Language",
      "Logout": "Logout",
      "Hide":"Hide",
      // MakeDecision
      "delete": "delete",
      "driver":"driver",
      "Are you sure you want to": "Are you sure you want to",
      "logout":"logout",
      "this": "this",
      "Cancel": "Cancel",
      "Ok": "Ok",
      // Pagination 
      "Prev": "Prev",
      "Next": "Next",
      "Showing": "Showing",
      "to": "to",
      "of": "of",
      "items":"items",
    }
  },
  Arabic: {
    translation: {
      // SelectLanguage
      "Choose Language": "اختر اللغة",
      English: "الإنكليزية",
      Arabic: "العربية",
      // Admin
            "Welcome": "أهلا بكم",
      "Please login": "الرجاء تسجيل الدخول",
      "Email": "البريد الإلكتروني",
      "Password":"كلمة المرور",
      "Your Email": "البريد الإلكتروني",
      "Your Password": "كلمة المرور",
      "Login": "تسجيل دخول",
      // MainDashPage 
      "Orders for today": "الطلبات لهذا اليوم",
      "Total orders": "كل الطلبات",
      "Pending orders": "الطلبات المعلقة",
      "Cars in service": "سيارات في الخدمة",
      "Income this month": "الدخل هذا الشهر",
      "Orders Chart": "مخطط الطلبات",
      "Daily": "يومي",
      "Monthly": "شهري",
      "Yearly": "سنوي",
      "Start Date": "تاريخ البدء",
      "End Date": "تاريخ الانتهاء",
      "Update": "تحديث",
      // Dashboard
      "Super Admin": "السوبر ادمن",
            // Laundries 
      "Add new laundry": "إضافة مغسلة جديدة",
      "Id": "الرقم التسلسلي",
      "Name": "الاسم",
      "Description": "الوصف",
      "Phone Number": "رقم الهاتف",
      "Address": "العنوان",
      "Admin id": "الرقم التسلسلي للمسؤول",
      "Status": "الحالة",
      "Image": "الصورة",
      "Active": "فعال",
      "Not active": "غير فعال",
      "More": "المزيد",
      "No laundries available": "لا يوجد مغاسل متاحة",
            // LaundryById 
      "Edit this laundry": "تعديل المغسلة",
      "Lat,Lng": "خطوط العرض و الطول",
      "Point": "النقاط",
      "Laundry Items": "منتجات المغسلة",
      "Price": "السعر",
      "Laundry Services": "خدمات المغسلة",
      "Service Name": "اسم الخدمة",
      "Laundry Images": "صور المغسلة",
      "Laundry Orders": "طلبات المغسلة",
      "Laundry Name": "اسم المغسلة",
      "Order Number": "رقم الطلب",
      "Order Source": "مصدر الطلب",
      "Order Owner": "صاحب الطلب",
      "Status": "الحالة",
      "Base Cost": "التكلفة الأساسية",
      "Total Price": "السعر الإجمالي",
      "Note": "ملاحظة",
      "Order Date": "تاريخ الطلب",
      "Pickup Time": "وقت الاستلام",
      "Delivery Time": "موعد التسليم",
      "No orders available": "لا توجد طلبات متاحة",
      "Laundry Drivers": "سائقين المغسلة",
      "Driver id": "الرقم التسلسلي للسائق",
      "No drivers available": "لا يوجد سائقين متاحين",
      "Laundry Cars": "سيارات المغسلة",
      "Driver Name": "اسم السائق",
      "Lat":"خطوط العرض",
      "Lng": "خطوط الطول",
      "No cars available": "لا يوجد سيارات متاحة",
              // AddLaundry
      "Name In English": "الاسم باللغة الانجليزية",
      "Name In Arabic": "الاسم باللغة العربية",
      "Description In English": "الوصف باللغة الإنجليزية",
      "Description In Arabic": "الوصف باللغة العربية",
      "Phone Number": "رقم الهاتف",
      "City": "المدينة",
      "Point": "النقاط",
      "Select Admin": "اختر مسؤول",
      "Select Items": "اختر منتجات",
      "Select Services": "اختر خدمات",
      "Lat": "خطوط العرض",
      "Lng": "خطوط الطول",
      "Images": "الصور",
      "Add": "إضافة",
            // UpdateLaundry 
      "Update laundry": "تعديل المغسلة",
      "Update":"تحديث",
      // EmployeesAndCars
      "Car Id": "الرقم التسلسلي للسيارة",
      "Driver Name": "اسم الساثق",
      "Details": "التفاصيل",
      "More": "المزيد",
      "No data available": "لا يوجد بيانات متاحة",
      // DriverById
      "Driver id": "الرقم التسلسلي للساثق",
      "Driver name": "اسم الساثق",
      "Driver email": "ايميل السائق",
      "Driver phone": "رقم السائق",
      "Car location":"موقع السيارة",
      // Orders
      "Orders All Time": "كل الأوقات",
      "Orders For Today": "اليوم",
      "All Orders": "كل الطلبات",
      "Indirect": "الغير مباشرة",
      "Direct": "المباشرة",
      "Id": "الرقم التسلسلي",
      "Status": "الحالة",
      "Base Cost": "التكلفة الأساسية",
      "Total Price": "السعر الكلي",
      "Note": "ملاحظة",
      "Order Date": "تاريخ الطلب",
      "Pickup Time": "وقت الاستلام",
      "Delivery Time": "وقت التسليم",
      "Less": "أقل",
      "More": "المزيد",
      "No orders available": "لا يوجد بيانات متاحة",
      // OrdersStatsChart
      "Orders":"الطلبات",
      // UpdateDeliveryTime
      "Edit Order": "تعديل الطلب",
      "Choose Date":"اختر التاريخ",
      "Choose Time": "اختر الوقت",
      "Select Car": "اختر السيارة",
      "Select Status": "اختر الحالة",
      "Pending": "معلق",
      "Confirmed": "مؤكد",
      "Request":"غير مؤكد",
      "Cancelled": "ملغي",
      "Update": "تحديث",
      // ProductItem
      "Edit":"تعديل",
      // UpdateProductPrice
      "Update product price": "تعديل السعر",
      "Product Price": "سعر المنتج",
"Update": "تحديث",     
 // DashboardSidebar
"Dobe Pro": "دوبي برو",
      "Dashboard": "الرئيسية",
  "Laundries": "المغاسل",
      "Admins": "المسؤولين",
      "Drivers": "السائقين",
  "Cars":"السيارات",
  "Employees And Cars": "السائقين و السيارات",
  "Orders": "الطلبات",
  "Transactions": "Transactions",
  "Prices": "الأسعار",
  "Reports": "التقارير",
  "Language": "اللغة",
      "Logout": "تسجيل خروج",
      "Hide":"غلق النافذة",
      // MakeDecision
      "delete": "تحذف",
      "driver":"الساثق",
      "Are you sure you want to": "هل انت متأكد أنك تريد",
      "logout": "تسجيل الخروج",
      "this": "هذا",
      "Cancel": "إلغاء",
      "Ok": "تأكيد",
      // Pagination 
      "Prev": "السابق",
      "Next": "التالي",
      "Showing": "عرض",
      "to": "إلى",
      "of": "من أصل",
      "items":"عناصر",
    }
  },
};

const lng = localStorage.getItem("dpLanguage") || "English";
i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
