import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDashboard } from "../Context/DashboardContext";
import { useTranslation } from "react-i18next";
import { updateLaundryStatus, updateLaundryUgentStatus } from "../Api/Laundry";
import { toastSuccess } from "../Components/ToastNotification/Toast";
import { Link } from "react-router-dom";
import Pagination from "../Components/Pagination";
import OutterTable from "../Components/OutterTable";
import InnerTable from "../Components/InnerTable";

function Laundries() {
  const lng = localStorage.getItem("dpLanguage") || "English";
  const { t } = useTranslation();
  const [moreOpen, setMoreOpen] = useState({});

  const {
    dir,
    updateLaundriesData,
    setUpdateLaudriesData,
    laundries,
    laundriesLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    itemPerPage,
    total,
    from,
    to,
    getLaundriesData,
  } = useDashboard();

  const toggleMoreOpen = (orderId) => {
    setMoreOpen((prevState) => {
      // Reset all other orders' moreOpen states to false
      const newState = {};

      // Open the clicked order's more details
      newState[orderId] = !prevState[orderId];

      // Close all other orders' more details
      Object.keys(prevState).forEach((key) => {
        if (key !== orderId.toString()) {
          newState[key] = false;
        }
      });

      return newState;
    });
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const handleUpdateStatus = async (id) => {
    try {
      const response = await updateLaundryStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateLaudriesData((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update laundry status err : ", err);
    }
  };

  const handleUpdateUgent = async (id) => {
    try {
      const response = await updateLaundryUgentStatus(id);
      console.log(response);
      if (response.success === true) {
        setUpdateLaudriesData((prev) => prev + 1);
        toastSuccess(response.message);
      }
    } catch (err) {
      console.error("update laundry ugent status err : ", err);
    }
  };

  useEffect(() => {
    getLaundriesData(currentPage);
  }, [dir, updateLaundriesData, currentPage]);

  return (
    <div className="flex gap-3 max-md:min-h-screen flex-col">
      <Link
        to={`addNewLaundry`}
        className="bg-white outline-none focus:ring-0 w-fit max-md:w-full min-w-[122px] min-h-[38.34px] font-semibold h-fit flex justify-between items-center gap-3 py-[7.5px] px-[12px] rounded-[0.25rem] text-MainText text-[13px] shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300"
      >
        {t("Add new laundry")}
      </Link>
      <div className="flex flex-col gap-3">
        <OutterTable>
          <thead>
            <tr>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] w-[70px]">
                {t("Id")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] ">
                {t("Name")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Description")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Phone Number")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] md:hidden w-[70px]"></th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Direct Orders")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Admin id")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Status")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden">
                {t("Img")}
              </th>
              <th className="border border-[#9ea5ad] p-[12px] text-[13px] max-md:hidden w-[70px]">
                ...
              </th>
            </tr>
          </thead>
          <tbody>
            {laundriesLoading ? (
              Array.from({ length: 11 }).map((_, outIndex) => (
                <tr key={outIndex}>
                  {Array.from({ length: 9 }).map((_, inIndex) => (
                    <td
                      key={inIndex}
                      className={`${inIndex > 2 ? "max-md:hidden" : ""}`}
                    >
                      <Skeleton
                        width={"100%"}
                        className="h-[55px] max-md:h-[25px]"
                      />
                    </td>
                  ))}
                </tr>
              ))
            ) : laundries.length > 0 ? (
              laundries.map((laundry) => (
                <>
                  <tr key={laundry.id} className="">
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] w-[70px]">
                      {laundry.id}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                      {lng === "Arabic" ? laundry.name_ar : laundry.name_en}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {lng === "Arabic"
                        ? laundry.description_ar
                        : laundry.description_en}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {laundry.phone_number}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] md:hidden w-[70px]">
                      <span
                        className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300"
                        onClick={() => toggleMoreOpen(laundry.id)}
                      >
                        {moreOpen[laundry.id] ? t("Less") : t("More")}
                      </span>
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      <select
                        onChange={() => handleUpdateUgent(laundry.id)}
                        value={laundry.urgent === 1 ? 1 : 0}
                        className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                      >
                        <option className="bg-white text-MainText" value={1}>
                          {t("Support")}
                        </option>
                        <option className="bg-white text-MainText" value={0}>
                          {t("Does not support")}
                        </option>
                      </select>
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      {laundry.admin_id}
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      <select
                        onChange={() => handleUpdateStatus(laundry.id)}
                        value={laundry.isActive === 1 ? "Active" : "Not active"}
                        className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                      >
                        <option
                          className="bg-white text-MainText"
                          value="Active"
                        >
                          {t("Active")}
                        </option>
                        <option
                          className="bg-white text-MainText"
                          value="Not active"
                        >
                          {t("Not active")}
                        </option>
                      </select>
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden">
                      <img
                        className="w-full h-full max-w-[200px] max-h-[150px] mx-auto object-cover"
                        src={laundry?.laundry_media[0]?.url_image}
                        alt=""
                      />
                    </td>
                    <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px] max-md:hidden w-[70px]">
                      <Link to={`${laundry.id}`}>
                        <span className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300">
                          {t("More")}
                        </span>
                      </Link>
                    </td>
                  </tr>
                  <InnerTable openStatus={moreOpen[laundry.id]}>
                    <tbody>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Id")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {laundry.id}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Name")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {lng === "Arabic" ? laundry.name_ar : laundry.name_en}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Description")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {lng === "Arabic"
                            ? laundry.description_ar
                            : laundry.description_en}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Phone Number")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {laundry.phone_number}
                        </td>
                      </tr>

                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Direct Orders")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          <select
                            onChange={() => handleUpdateUgent(laundry.id)}
                            value={laundry.urgent === 1 ? 1 : 0}
                            className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText   duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                          >
                            <option
                              className="bg-white text-MainText"
                              value={1}
                            >
                              {t("Support")}
                            </option>
                            <option
                              className="bg-white text-MainText"
                              value={0}
                            >
                              {t("Does not support")}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Admin id")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {laundry.admin_id}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Status")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          <select
                            onChange={() => handleUpdateStatus(laundry.id)}
                            value={
                              laundry.isActive === 1 ? "Active" : "Not active"
                            }
                            className="py-[7.5px] px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                          >
                            <option
                              className="bg-white text-MainText"
                              value="Active"
                            >
                              {t("Active")}
                            </option>
                            <option
                              className="bg-white text-MainText"
                              value="Not active"
                            >
                              {t("Not active")}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          {t("Img")}
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          <img
                            className="w-full h-full max-w-[200px] max-h-[150px] object-cover mx-auto"
                            src={laundry?.laundry_media[0]?.url_image}
                            alt=""
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="font-bold text-center border border-[#9ea5ad] p-[12px] text-[13px]">
                          ...
                        </td>
                        <td className="border border-[#9ea5ad] text-center p-[12px] text-[13px]">
                          <div className="mx-auto flex items-center gap-2 w-fit">
                            <Link to={`${laundry.id}`}>
                              <span className="w-full h-full underline cursor-pointer text-PrimaryColor hover:text-HoverPrimaryColor duration-300">
                                {t("More")}
                              </span>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </InnerTable>
                </>
              ))
            ) : (
              <div className="whitespace-nowrap p-[12px] text-[13px]">
                {t("No laundries available")}
              </div>
            )}
          </tbody>
        </OutterTable>

        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
        />
      </div>
    </div>
  );
}

export default Laundries;
