import { toastError } from "../Components/ToastNotification/Toast";
import { apiAdmin } from "./api";

export const addAdmin = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/register-admin`, data);
    return response.data;
  } catch (error) {
    console.error("add admin err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0])
  }
};

