import { toastError } from "../Components/ToastNotification/Toast";
import { apiAdmin } from "./api";

export const fetchOrdersForToday = async (status_id = 1,page = 1) => {
  try {
    const response = await apiAdmin.get(`/api/MyOrders?status_id=${status_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("fetch orders for today err : ", error);
  }
};

export const fetchAllOrders = async (status_id = 1,page = 1) => {
  try {
    const response = await apiAdmin.get(`/api/filterMyOrder?status_id=${status_id}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("fetch all orders err : ", error);
  }
};

export const updateDeliveryDate = async (order_id,data) => {
  try {
    const response = await apiAdmin.post(`api/confirm-Order?order_id=${order_id}`,data);
    return response.data;
  } catch (error) {
    console.log("update delivery date err : ", error);
    toastError(error.response.data.data[0])
  }
};

export const fetchOrdersChart = async (start_date,end_date, status_id ) => {
  try {
    const response = await apiAdmin.get(`/api/order-stats?start_date=${start_date}&end_date=${end_date}&status_id=${status_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch orders chart err : ", error);
    toastError(error.response.data.data[0])
  }
};

export const fetchOrderById = async (order_id ) => {
  try {
    const response = await apiAdmin.get(`/api/OrderDetails?order_id=${order_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch order by id err : ", error);
    toastError(error.response.data.data[0])
  }
};

export const searchForOrder = async (order_number ,page = 1) => {
  try {
    const response = await apiAdmin.get(`/api/search-orders?number=${order_number}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("search for order err : ", error);
    toastError(error.response.data.data[0])
  }
};

export const addOrder = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/order`,data);
    return response.data;
  } catch (error) {
    console.log("add order err : ", error);
    toastError(error.response.data.data[0])
  }
};
export const addAddress = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/Address`,data);
    return response.data;
  } catch (error) {
    console.log("add address err : ", error);
    toastError(error.response.data.data[0])
  }
};