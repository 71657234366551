import React, { useEffect, useState } from "react";
import {
  fetchLaundryById,
  fetchLaundryItems,
  fetchServices,
  updateLaundry,
} from "../Api/Laundry";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { lng } from "../Api/api";
function UpdateLaundry() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [showPicOptions, setShowPicOptions] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsLoading, setItemsLoading] = useState(true);
  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);
  const [data, setData] = useState({
    nameEn: "",
    nameAr: "",
    descriptionEn: "",
    descriptionAr: "",
    phoneNumber: "",
    city: "",
    address: "",
    point: "",
    items: [],
    services: [],
    imgs: [],
    showedPics: [],
  });
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleImageUpload = (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to array
    setData((prevData) => ({
      ...prevData,
      imgs: [...prevData.imgs, ...newFiles], // Append new images
    }));
  };
  const handleItemChange = (e, itemId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the selected item with price (if it has a price)
      setData((prevData) => ({
        ...prevData,
        items: [...prevData.items, { laundry_item_id: itemId, price: 0 }], // Set initial price to 0
      }));
    } else {
      // Remove the unselected item
      setData((prevData) => ({
        ...prevData,
        items: prevData.items.filter((item) => item.laundry_item_id !== itemId),
      }));
    }
  };

  const handlePriceChange = (itemId, newPrice) => {
    setData((prevData) => ({
      ...prevData,
      items: prevData.items.map((item) =>
        item.laundry_item_id === itemId ? { ...item, price: newPrice } : item
      ),
    }));
  };

  const handleServiceChange = (e, serviceId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the selected service
      setData((prevData) => ({
        ...prevData,
        services: [...prevData.services, { service_id: serviceId }],
      }));
    } else {
      // Remove the unselected service
      setData((prevData) => ({
        ...prevData,
        services: prevData.services.filter(
          (service) => service.service_id !== serviceId
        ),
      }));
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name_en", data.nameEn);
    formData.append("name_ar", data.nameAr);
    formData.append("description_en", data.descriptionEn);
    formData.append("description_ar", data.descriptionAr);
    formData.append("phone_number", data.phoneNumber);
    formData.append("city", data.city);
    formData.append("address_line_1", data.address);
    formData.append("point", data.point);
    // Append image data (handle multiple images)
    data.imgs.forEach((img, index) => {
      formData.append(`array_url[${index}][image_ids]`, img.image_ids);
      formData.append(`array_url[${index}][url_image]`, img.url_image);
    });

    // Append laundry items
    data.items.forEach((item, index) => {
      formData.append(
        `array_ids[${index}][laundry_item_id]`,
        item.laundry_item_id
      );
      formData.append(`array_ids[${index}][price]`, item.price);
    });

    // Append services
    data.services.forEach((service, index) => {
      formData.append(
        `array_service[${index}][service_id]`,
        service.service_id
      );
    });
    try {
      setLoading(true);
      const response = await updateLaundry(id, formData);
      console.log(response);
      if (response.success === true) {
        window.location.pathname = "/dashboard/laundries";
      }
    } catch (err) {
      console.error("update laundry err : ", err);
    } finally {
      setLoading(false);
    }
  };
  function getMimeType(extension) {
    switch (extension) {
      case "jpg":
      case "jpeg":
        return "image/jpeg";
      case "png":
        return "image/png";
      case "gif":
        return "image/gif";
      case "bmp":
        return "image/bmp";
      case "svg":
        return "image/svg+xml";
      case "webp":
        return "image/webp";
      default:
        return "application/octet-stream"; // Fallback for unknown types
    }
  }
function getFileSizeInBytes(url) {
  console.log("Calculating size for:", url);
  const estimatedSize = Math.floor(Math.random() * 1000000) + 100000;
  console.log("Estimated size:", estimatedSize);
  return estimatedSize;
}
const getLaundryById = async () => {
  try {
    setDataLoading(true);
    const response = await fetchLaundryById(id);
    console.log(`laundry with id = ${id} : `, response);
    setData((data) => ({
      ...data,
      nameEn: response.data.name_en,
      nameAr: response.data.name_ar,
      descriptionEn: response.data.description_en,
      descriptionAr: response.data.description_ar,
      phoneNumber: response.data.phone_number,
      city: response.data.address?.city,
      address: response.data.address?.address_line_1,
      point: response.data.point,
      items: response.data.LaundryItem.map((item) => ({
        laundry_item_id: item.pivot.laundry_item_id,
        price: item.pivot.price,
      })),
      services: response.data.services.map((item) => ({
        service_id: item.pivot.service_id,
      })),
      imgs: response.data.LaundryMedia.map((item) => {
        const fileExtension = item.url_image.split(".").pop().toLowerCase();
        const mimeType = getMimeType(fileExtension);
        const fileSize = getFileSizeInBytes(item.url_image);
        const file = new File([new Blob()], item.url_image.toString(), {
          type: mimeType,
          size: fileSize,
          url_image: item.url_image,
        });
        return {
          image_ids: item.id,
          url_image: file,
        };
      }),
      showedPics: response.data.LaundryMedia.map((item) => ({
        image_ids: item.id,
        url_image: item.url_image,
      })),
    }));
  } catch (err) {
    console.error("get laundry by id err : ", err);
  } finally {
    setDataLoading(false);
  }
};

  const getLaundryItems = async () => {
    try {
      setItemsLoading(true);
      const response = await fetchLaundryItems();
      console.log(response);
      setItems(response.data);
    } catch (err) {
      console.error("get laundry items err : ", err);
    } finally {
      setItemsLoading(false);
    }
  };
  const getServices = async () => {
    try {
      setServicesLoading(true);
      const response = await fetchServices();
      console.log(response);
      setServices(response.data);
    } catch (err) {
      console.error("get services err : ", err);
    } finally {
      setServicesLoading(false);
    }
  };

  useEffect(() => {
    getLaundryById();
  }, []);
  useEffect(() => {
    getLaundryItems();
    getServices();
  }, [lng]);
  useEffect(() => {
    console.log("data is : ", data);
  }, [data]);
  return (
    <form
      className="flex flex-col gap-5 relative max-w-[1200px]"
      onSubmit={submitHandler}
    >
      <div className="font-[500]">{t("Update laundry")}</div>
      <div className="flex flex-col gap-3">
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameEn"
            >
              {t("Name In English")} <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <input
                value={data.nameEn}
                name="nameEn"
                onChange={changeHandler}
                id="nameEN"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Name In English")}
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="nameAr"
            >
              {t("Name In Arabic")} <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <input
                value={data.nameAr}
                name="nameAr"
                onChange={changeHandler}
                id="nameAr"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Name In Arabic")}
              />
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionEn"
            >
              {t("Description In English")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <textarea
                value={data.descriptionEn}
                name="descriptionEn"
                onChange={changeHandler}
                id="descriptionEn"
                className="resize-none max-h-[100px] py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[100px]"
                placeholder={t("Description In English")}
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="descriptionAr"
            >
              {t("Description In Arabic")}{" "}
              <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <textarea
                value={data.descriptionAr}
                name="descriptionAr"
                onChange={changeHandler}
                id="descriptionAr"
                className="resize-none max-h-[100px] py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[100px]"
                placeholder={t("Description In Arabic")}
              />
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="phoneNumber"
            >
              {t("Phone Number")} <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <input
                type="number"
                value={data.phoneNumber}
                name="phoneNumber"
                onChange={changeHandler}
                id="phoneNumber"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={"ex: 0963 654 656 65"}
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="city"
            >
              {t("City")} <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <input
                value={data.city}
                name="city"
                onChange={changeHandler}
                id="city"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("City")}
              />
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="address"
            >
              {t("Address")} <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <input
                value={data.address}
                name="address"
                onChange={changeHandler}
                id="address"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Address")}
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <label
              className="text-[13px] flex items-center gap-1 cursor-pointer"
              htmlFor="point"
            >
              {t("Point")} <span className="text-red-600">*</span> :
            </label>
            {dataLoading ? (
              <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
            ) : (
              <input
                value={data.point}
                name="point"
                onChange={changeHandler}
                id="point"
                className="py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                placeholder={t("Point")}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="items"
          >
            {t("Select Items")} <span className="text-red-600">*</span> :
          </label>
          {itemsLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div id="items" className="flex flex-col gap-2 text-[13px] w-full">
              {items.map((item) => {
                const isChecked = data.items.some(
                  (selectedItem) => selectedItem.laundry_item_id === item.id
                );

                return (
                  <div
                    className="text-MainText bg-white flex md:justify-between md:items-center max-md:flex-col max-md:gap-2 shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                    key={item.id}
                  >
                    <div className="flex items-center gap-2">
                      <input
                        id={`item-${item.id}`}
                        type="checkbox"
                        className="focus:ring-0"
                        checked={isChecked}
                        onChange={(e) =>
                          handleItemChange(e, item.id, item.price)
                        }
                      />
                      <label htmlFor={`item-${item.id}`}>
                        {lng === "Arabic"
                          ? item.item_type_ar
                          : item.item_type_en}
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <label htmlFor={`price-${item.id}`}>{t("Price")}</label>
                      <input
                        className="focus:ring-0 text-MainText bg-[#e2e2e2] hover:bg-[#c8c8c8] duration-300 outline-none"
                        id={`price-${item.id}`}
                        type="number"
                        value={
                          isChecked &&
                          data.items.find((i) => i.laundry_item_id === item.id)
                            ?.price
                        }
                        disabled={!isChecked} // Disable if the item is not checked
                        onChange={(e) =>
                          handlePriceChange(item.id, parseFloat(e.target.value))
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="services"
          >
            {t("Select Services")} <span className="text-red-600">*</span> :
          </label>
          {servicesLoading ? (
            <Skeleton className="text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText rounded-[0.25rem] cursor-pointer min-h-[38.34px]" />
          ) : (
            <div
              id="services"
              className="grid grid-cols-2 max-md:grid-cols-1 gap-3 text-[13px] w-full"
            >
              {services.map((service) => {
                const isChecked = data.services.some(
                  (selectedService) => selectedService.service_id === service.id
                );
                return (
                  <div
                    className="text-MainText bg-white flex justify-between items-center shadow-lg border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 py-[7.5px] px-[12px] rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
                    key={service.id}
                  >
                    <div className="flex items-center gap-2">
                      <input
                        id={`service-${service.id}`}
                        type="checkbox"
                        className="focus:ring-0"
                        checked={isChecked}
                        onChange={(e) => handleServiceChange(e, service.id)}
                      />
                      <label htmlFor={`service-${service.id}`}>
                        {lng === "Arabic" ? service.name_ar : service.name_en}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <label
            className="text-[13px] flex items-center gap-1 cursor-pointer"
            htmlFor="img"
          >
            {t("Imgs")} <span className="text-red-600">*</span> :
          </label>
          <input
            multiple
            onChange={handleImageUpload}
            type="file"
            id="img"
            className="bg-white py-[7.5px] shadow-lg px-[12px] text-[13px] w-full outline-none focus:ring-0 border border-[#9ea5ad] hover:border-MainText focus:border-MainText duration-300 rounded-[0.25rem] cursor-pointer min-h-[38.34px]"
            placeholder={t("Imgs")}
          />
        </div>
        {data.showedPics.length > 0 && (
          <div className="grid grid-cols-3 max-md:grid-cols-2 gap-1 border border-[#9ea5ad] shadow-lg rounded-[0.25rem] overflow-hidden bg-white">
            {data.showedPics.map((photo, index) => (
              <div
                onMouseOver={() => setShowPicOptions(true)}
                onMouseLeave={() => setShowPicOptions(false)}
                key={photo.id}
                className="w-full relative max-h-[300px] min-h-[300px] max-md:max-h-[200px] max-md:min-h-[100px]"
              >
                <div
                  className={`${
                    showPicOptions ? "md:block" : "md:hidden"
                  } animate-flashing absolute left-0 top-0 w-full h-full bg-black/25 max-md:bg-transparent`}
                >
                  <div className="mt-2 mx-2 w-fit cursor-pointer text-MainText flex justify-center items-center rounded-full p-2 bg-[#d8d7d7] hover:bg-[#5f5f5f] hover:text-white duration-300">
                    <AiOutlineClose size={15} />
                  </div>
                </div>
                <img
                  className="w-full h-full object-cover"
                  key={index}
                  src={photo.url_image}
                  alt={`image ${index}`}
                />
              </div>
            ))}
          </div>
        )}
        <button
          type="submit"
          onClick={submitHandler}
          className={`${
            loading ? "cursor-default pointer-events-none" : "cursor-pointer"
          } min-w-[81.38px] min-h-[38.34px] py-[7.5px] shadow-lg px-[12px] text-[13px] rounded-[0.25rem] w-fit bg-PrimaryColor hover:bg-HoverPrimaryColor text-white duration-300 flex justify-center items-center`}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin" />
          ) : (
            t("Update")
          )}
        </button>
      </div>
    </form>
  );
}

export default UpdateLaundry;
