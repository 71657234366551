import React from "react";
import { Link, useLocation } from "react-router-dom";
import { usePopup } from "../Context/PopupContext";

function SidebarLinkItem({ to, active, icon, content, mob }) {
  const { sideBarOpen, setDashboardSideBarOpen } = usePopup();
  const location = useLocation();
  function isActive(path) {
    return location.pathname === path;
  }
  return (
    <Link
      onClick={() => setDashboardSideBarOpen(false)}
      to={to}
      className={`flex items-center gap-2 w-full h-full p-3 text-[13px] transition duration-300 text-white hover:bg-[#066ea6]
                    ${active ? "bg-HoverPrimaryColor" : ""}`}
      exact="true"
    >
      {icon}
      <h1 className={`${!mob ? (sideBarOpen ? "block" : "hidden") : null}`}>
        {content}
      </h1>
    </Link>
  );
}

export default SidebarLinkItem;
