import React from "react";
import { GiWashingMachine } from "react-icons/gi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function LoadingPage() {
  return (
    <div className="h-screen flex justify-center items-center bg-gradient-to-b from-[#78bcd0] to-[#00238c]">
      <div className="flex justify-center items-center relative">
        <GiWashingMachine className="absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] text-white text-[35px]" />
        <AiOutlineLoading3Quarters className="text-[55px] text-white animate-spin" />
      </div>
    </div>
  );
}

export default LoadingPage;
