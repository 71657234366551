import { apiAdmin } from "./api";

export const fetchAdvertisements = async (status_id,laundry_id,query,page) => {
  try {
    const response = await apiAdmin.get(`/api/getAdvertisement?status_id=${status_id}&laundry_id=${laundry_id}&name=${query}&page=${page}`);
    return response.data;
  } catch (error) {
    console.log("fetch advertisements err : ", error);
  }
};

export const fetchAdvertisementById = async (advertisement_id) => {
  try {
    const response = await apiAdmin.get(`/api/ads-id?advertisement_id=${advertisement_id}`);
    return response.data;
  } catch (error) {
    console.log("fetch advertisement by id err : ", error);
  }
};

export const addAdvertisement = async (laundry_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/Advertisement?laundry_id=${laundry_id}`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
    console.log("add advertisement err : ", error);
  }
};

export const updateAdvertisement = async (advertisement_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/Advertisement-update?advertisement_id=${advertisement_id}`,data);
    return response.data;
  } catch (error) {
    console.log(`update advertisement number ${advertisement_id} err : `, error);
  }
};

export const updateAdvertisementMedia = async (advertisement_id,data) => {
  try {
    const response = await apiAdmin.post(`/api/AdvertisementMedia?advertisement_id=${advertisement_id}`,data,{headers: {
        "Content-Type": "multipart/form-data",
      },});
    return response.data;
  } catch (error) {
    console.log(`update advertisement media number ${advertisement_id} err : `, error);
  }
};

export const deleteAdvertisementMediaItem = async (advertisement_id) => {
  try {
    const response = await apiAdmin.delete(`/api/AdvertisementMedia?advertisement_media_id=${advertisement_id}`);
    return response.data;
  } catch (error) {
    console.log(`update advertisement media number ${advertisement_id} err : `, error);
  }
};

export const changeAdvertisementStatus = async (advertisement_id,status_id) => {
  try {
    const response = await apiAdmin.get(`/api/confirmAdvertisement?advertisement_id=${advertisement_id}&status_id=${status_id}`);
    return response.data;
  } catch (error) {
    console.log("change advertisement status err : ", error);
  }
};