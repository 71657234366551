import React from "react";

function InnerTable({ children, openStatus }) {
  return (
    <div className={`${openStatus ? "block" : "hidden"} innerTable md:hidden`}>
      <table className="w-full">{children}</table>
    </div>
  );
}

export default InnerTable;
