import { toastError } from "../Components/ToastNotification/Toast";
import { apiAdmin } from "./api";

export const adminLogin = async (data) => {
  try {
    const response = await apiAdmin.post(`/api/loginAdmin`, data);
    return response.data;
  } catch (error) {
    console.error("admin login err : ", error.response.data.data[0]);
    toastError(error.response.data.data[0])
  }
};

export const logout = async () => {
  try {
    const response = await apiAdmin.get(`/api/logout`);
    return response.data;
  } catch (error) {
    console.log("logout err : ", error);
  }
};

