import { React, useState } from "react";
import { usePopup } from "../Context/PopupContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Popup from "./Popup";
import { logout } from "../Api/Auth";
import Cookies from "js-cookie";
import { toastSuccess } from "./ToastNotification/Toast";
import { useTranslation } from "react-i18next";
import { isSuper } from "../Api/api";
import { deleteDriver } from "../Api/Drivers";
import { useDashboard } from "../Context/DashboardContext";
import { useParams } from "react-router-dom";
import { deleteAdvertisementMediaItem } from "../Api/Advertisments";

function MakeDecision() {
  const { t } = useTranslation();
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const {
    makeDecisionOpen,
    setMakeDecisionOpen,
    setDashboardSideBarOpen,
    type,
    action,
    itemId,
  } = usePopup();
  const { id } = useParams();
  const { setUpdateAdPics, setUpdateDrivers } = useDashboard();
  const handleConfirm = async () => {
    setSpinnerLoad(true);
    if (action === "logout") {
      try {
        const response = await logout();
        console.log(response);
        if (response.success === true) {
          isSuper
            ? Cookies.remove("dpSuperAdminToken")
            : Cookies.remove("dpAdminToken");
          localStorage.removeItem("laundryId");
          window.location.pathname = "/";
          toastSuccess(response.message);
        }
      } catch (error) {
        console.error(`${action} ${type} Error`, error);
      } finally {
        setSpinnerLoad(false);
        setMakeDecisionOpen(false);
        setDashboardSideBarOpen(false);
      }
    } else if (action === "delete" && type === "driver") {
      try {
        const response = await deleteDriver(itemId);
        console.log(response);
        toastSuccess(response.message);
      } catch (error) {
        console.error(`delete ${type} err : `, error);
      } finally {
        setSpinnerLoad(false);
        setMakeDecisionOpen(false);
        setUpdateDrivers(false);
      }
    } else if (action === "delete" && type === "ad") {
      try {
        const response = await deleteAdvertisementMediaItem(itemId);
        console.log(response);
        if (response.success) {
          setUpdateAdPics((prev) => prev + 1);
          toastSuccess(response.message);
        }
      } catch (error) {
        console.error(`delete ${type} err : `, error);
      } finally {
        setSpinnerLoad(false);
        setMakeDecisionOpen(false);
      }
    }
  };
  return (
    <Popup
      toggleSwitch={makeDecisionOpen}
      children={
        <div
          className={`text-MainText bg-white max-md:min-w-full overflow-hidden flex flex-col gap-2`}
        >
          <h1 className="font-[500]">
            {t("Are you sure you want to")}{" "}
            {action === "logout" ? t("logout") : t("delete")}{" "}
            {type
              ? `${t("this")} ${
                  type === "driver" ? t("driver") : t("advertisment")
                }`
              : null}{" "}
            ?
          </h1>
          <div className="flex items-center justify-start mt-3 gap-3">
            <button
              onClick={() => setMakeDecisionOpen(false)}
              className="py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText   duration-300 flex justify-center items-center min-w-[64px] min-h-[38.34px]"
            >
              {t("Cancel")}
            </button>

            <button
              onClick={handleConfirm}
              className={`${
                spinnerLoad
                  ? "cursor-default pointer-events-none"
                  : "cursor-pointer"
              } py-[7.5px] px-[12px] text-[13px] rounded-[0.25rem] border border-[#9ea5ad] hover:border-MainText   duration-300 flex justify-center items-center min-w-[64px] min-h-[38.34px]`}
            >
              {spinnerLoad ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                t("Ok")
              )}
            </button>
          </div>
        </div>
      }
      overLayClassName={`z-40`}
      className={`text-MainText bg-white top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] fixed z-50 overflow-hidden rounded-[0.25rem] p-4`}
      onClose={() => setMakeDecisionOpen(false)}
    />
  );
}

export default MakeDecision;
